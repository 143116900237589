import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import AuthWarningPopup from './AuthWarningPopup'; // Import the popup component

const ProtectedRoute = ({ children, allowedRoles }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false); // New state to handle redirect

  // Function to check if the user is authenticated and has the correct role
  const checkAuth = () => {
    const token = localStorage.getItem('token'); // Assuming you're storing the JWT token in local storage
    
    

    if (!token) {
      setShowPopup(true); // Show popup if no token
      const timer = setTimeout(() => {
        setRedirectToLogin(true); // Set redirect after 3 seconds
      }, 3000); // Redirect after 3 seconds (adjust as needed)

      return;
    }

    // Decode the token to get user info
    try {
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role; // Adjust this based on your token structure

      // Check if the user role is allowed
      if (!allowedRoles.includes(userRole)) {
        setShowPopup(true); // Show popup if role is not allowed
        const timer = setTimeout(() => {
          setRedirectToLogin(true); // Set redirect after 3 seconds
        }, 3000); // Redirect after 3 seconds (adjust as needed)
      }
    } catch (error) {
      setShowPopup(true); // Show popup if token decoding fails
      const timer = setTimeout(() => {
        setRedirectToLogin(true); // Set redirect after 3 seconds
      }, 3000); // Redirect after 3 seconds (adjust as needed)
    }
  };

  // Check authentication on component mount
  useEffect(() => {
    checkAuth();
  }, []);

  // If the user is not authenticated or doesn't have the right role, show the popup
  if (showPopup) {
    return (
      <>
        {redirectToLogin && <Navigate to="/login" />}
        <AuthWarningPopup onClose={() => setShowPopup(false)} />
      </>
    );
  }

  return children; // If authenticated and role is allowed, render the children
};

export default ProtectedRoute;
