const LoadingPage=()=>{
    return( <div className="w-full h-screen flex flex-col  absolute inset-0 backdrop-blur-sm justify-center items-center text-center">
        <img
         src="/images/ani-ico.gif"
         width="100"
         height="100"
         alt="ChaiBook Logo"
         className="animate-fade-in-up " 
       />
       <span>Loading..</span>
     </div>)
}
export default LoadingPage;