import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import useAddBuildings from "../../../../hooks/buildings/useAddBuildings";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

const NewBuildingModal = ({ isOpen, onClose }) => {
  const [buildingName, setBuildingName] = useState("");
  const [address, setAddress] = useState("");
const qryclient = useQueryClient();
  // Disable background scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Cleanup on unmount
    };
  }, [isOpen]);
  const {mutate:addBuildingMutate}=useAddBuildings();
  // Handle form submission
  const handleAddBuilding = () => {
    if(buildingName.length<=0 || address.length<=0)
    {
      toast.error("name and address must be valid..");
      return;
    }
    addBuildingMutate({
      name:buildingName,
      address:address
    },{
    onSuccess:(data)=>{
      toast.success("Building Added..");
      qryclient.invalidateQueries(['getBuildings']);
      setBuildingName("");
      setAddress("");
      onClose();
    },onError:(error)=>{
      toast.error("problem while adding building..");
    }
    })
  };

  return (
    <>
      {isOpen && (
        <div className="fixed h-full inset-0 z-50 flex items-end justify-center">
          {/* Background overlay with blur effect */}
          <div
            className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
            onClick={onClose}
          ></div>

          {/* Modal content */}
          <div className="relative w-full h-[70%] bg-white rounded-t-3xl shadow-lg p-6">
            {/* Modal Title */}
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-semibold">Add New Building</h2>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={onClose}
              >
                <FaTimes size={24} />
              </button>
            </div>

            {/* Form Fields */}
            <div className="space-y-4">
              {/* Building Name Input */}
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  placeholder="Building Name"
                  value={buildingName}
                  onChange={(e) => setBuildingName(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>

              {/* Address Input */}
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-between mt-6">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleAddBuilding}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Add Building
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewBuildingModal;
