// src/hooks/useGetItems.js
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosInstance';


export const useGetItems = (isActive) => {
  return useQuery({
    queryKey: ['getItems', isActive],
    queryFn: async () => {
      const response = await axiosInstance.get(`/outlet/item/getitems/${isActive}`);
      if (!response.data) {
        throw new Error('Failed to fetch items.');
      }
      return response.data;
    },
  });
};
