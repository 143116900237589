import React, { useEffect, useRef, useState } from "react";
import {
  FaTimes,
  FaUser,
  FaPhone,
  FaBuilding,
  FaMapMarkerAlt,
  FaMailBulk,
} from "react-icons/fa";
import NewBuildingModal from "./NewBuildingModel";
import useGetBuildings from "../../../../hooks/buildings/useGetBuildings";
import useAddCustomer from "../../../../hooks/customers/addCustomer";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

const AddNewCustomer = ({ isOpen, onClose }) => {
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchCurrentY, setTouchCurrentY] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("office");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  // const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [countdown, setCountdown] = useState(5); // Countdown timer
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [isNewBuildingModalOpen, setIsNewBuildingModalOpen] = useState(false);
  const timer = useRef(null);
  // const [buildingsData, setBuildingsData] = useState([]);
  // New state for the building modal

  // Disable background scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Cleanup on unmount
    };
  }, [isOpen]);

  // // Check if there are unsaved changes
  // useEffect(() => {
  //   if (name || contact || email) {
  //     setHasUnsavedChanges(true);
  //   } else {
  //     setHasUnsavedChanges(false);
  //   }
  // }, [name, contact, email]);
  const hasUnsavedChanges = name || contact || email;
  const { data, isLoading, error } = useGetBuildings();
  const buildingsData = data?.building;

  // useEffect(() => {
  //   setBuildingsData(data?.building);
  // }, [data]);

  // Detect swipe down to close the modal
  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setTouchCurrentY(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (touchStartY && touchCurrentY && touchCurrentY - touchStartY > 100) {
      // If swipe distance is greater than 100px, check for unsaved changes
      if (hasUnsavedChanges) {
        setShowWarning(true); // Show the warning modal if there are unsaved changes
      } else {
        closeWithAnimation(); // Close the modal without showing the warning
      }
    } else {
      setTouchCurrentY(null);
    }
    setTouchStartY(null);
  };

  // Close the modal with animation
  const closeWithAnimation = () => {
    if (timer.current) clearInterval(timer.current);
    setCountdown(5);
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
      setShowWarning(false);
    }, 500); // Duration of the closing animation
  };
  const queryClient = useQueryClient();
  // Handle form submission
  const { mutate: addcutomer } = useAddCustomer();
  const handleAddCustomer = () => {
    const payload = {
      name: name,
      type: type,
      contact: { phone: contact, email: email },
    };

    if (type === "office") {
      payload.building = selectedBuilding;
    }
    addcutomer(payload, {
      onSuccess: (data) => {
        toast.success(data.message || "Cutomer Added..");
        handleClearForm();
        closeWithAnimation();
        queryClient.invalidateQueries(["getcustomers"])
      },
      onError: (error) => {
        // console.log(error);

        toast.error(error.response.data.message || "Cutomer not added..");
      },
    });
  };

  // Handle form clearing
  const handleClearForm = () => {
    setName("");
    setType("office");
    setContact("");
    setEmail("");
  };

  // Start countdown timer
  useEffect(() => {
    if (showWarning && countdown > 0) {
      timer.current = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer.current);
    } else if (countdown === 0) {
      setShowWarning(false); // Hide the warning after countdown
      setCountdown(5); // Reset countdown
    }
  }, [showWarning, countdown]);

  const swipeDistance =
    touchCurrentY && touchStartY
      ? Math.max(0, Math.min(touchCurrentY - touchStartY, 200))
      : 0;
  const opacity = Math.max(1 - swipeDistance / 300, 0.5);
  const translateY = swipeDistance;

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-end justify-center">
          {/* Background overlay with blur effect */}
          <div
            className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
            onClick={() => {
              if (hasUnsavedChanges) {
                setShowWarning(true);
              } else {
                closeWithAnimation();
              }
            }}
          ></div>

          {/* Modal content with swipe effect */}
          <div
            className={`relative w-full h-[80%]  bg-white rounded-t-3xl overflow-auto shadow-lg p-6 transition-transform duration-300 ease-in-out`}
            style={{
              maxWidth: "100%",
              opacity: isClosing ? 0 : opacity,
              transform: isClosing
                ? "translateY(100%)"
                : `translateY(${translateY}px)`,
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {/* Modal Title */}
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-semibold">Add New Customer</h2>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => {
                  if (hasUnsavedChanges) {
                    setShowWarning(true);
                  } else {
                    closeWithAnimation();
                  }
                }}
              >
                <FaTimes size={24} />
              </button>
            </div>

            {/* Warning message */}
            {showWarning && (
              <div className="bg-slate-100 bg-transparent rounded-lg p-2 mb-4 text-light-red-600 flex justify-between items-center">
                <p>You have unsaved changes. Closing {countdown} seconds</p>
                <button
                  onClick={() => {
                    setShowWarning(false);
                    handleClearForm();
                    closeWithAnimation();
                  }}
                  className="border border-red-500 text-red-500 px-2 py-1 rounded hover:bg-red-100 ml-2"
                >
                  Confirm
                </button>
              </div>
            )}

            {/* Form Fields */}
            <div className="space-y-4">
              {/* Name Input */}
              <div className="flex items-center space-x-2">
                <FaUser />
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>

              {/* Type Input */}
              <div className="flex items-center space-x-2">
                <FaBuilding />
                <select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="office">Office</option>
                  <option value="individual">Individual</option>
                  <option value="shop">Shop</option>
                </select>
              </div>
              {type === "office" && (
                <div className="flex items-center space-x-2 mb-4">
                  <FaBuilding />
                  <select
                    value={selectedBuilding}
                    onChange={(e) => setSelectedBuilding(e.target.value)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select Building</option>
                    {buildingsData?.map((building) => (
                      <option key={building._id} value={building._id}>
                        {`${building.name} (${building.address.slice(0, 15)}${
                          building.address.length >= 14 ? "..." : ""
                        })`}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={() => setIsNewBuildingModalOpen(true)}
                    className="px-3 py-2 flex text-nowrap w-40 justify-center text-center bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    Add Building
                  </button>
                </div>
              )}
              {/* Contact Input */}

              <div className="flex items-center space-x-2">
                <FaPhone />
                <input
                  type="text"
                  placeholder="Contact"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="flex items-center space-x-2">
                <FaMailBulk />
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-between mt-6">
              <button
                onClick={handleClearForm}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                Clear
              </button>
              <button
                onClick={handleAddCustomer}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Add
              </button>
            </div>
          </div>
          <NewBuildingModal
            isOpen={isNewBuildingModalOpen}
            onClose={() => setIsNewBuildingModalOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default AddNewCustomer;
