import React, { useEffect, useState } from "react";
import {
  FaSearch,
  FaRupeeSign,
  FaPrint,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import jsPDF from "jspdf";

const InvoiceComponent = () => {
  const [selectedTab, setSelectedTab] = useState("office");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("Pending");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fake data for customers
  const customers = [
    { id: 1, name: "ABC Office Solutions", type: "office" },
    { id: 2, name: "John Doe", type: "individual" },
    { id: 3, name: "XYZ Retail Shop", type: "shop" },
  ];

  // Fake data for invoices
  const invoiceData = {
    customerName: selectedCustomer ? selectedCustomer.name : "",
    billingDate: "Oct 31, 2024",
    deliveryRecords: [
      { itemName: "Tea", quantity: 20, price: 10, total: 200 },
      { itemName: "Coffee", quantity: 15, price: 15, total: 225 },
      { itemName: "Green Tea", quantity: 10, price: 12, total: 120 },
    ],
  };

  // Function to generate PDF
  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(20);
    doc.text("Invoice", 14, 22);
    doc.setFontSize(12);
    doc.text(`Customer: ${invoiceData.customerName}`, 14, 32);
    doc.text(`Billing Date: ${invoiceData.billingDate}`, 14, 42);
    doc.text("Items:", 14, 62);

    let y = 72;
    invoiceData.deliveryRecords.forEach((item) => {
      doc.text(
        `${item.itemName}: Qty ${item.quantity} x ₹${item.price} = ₹${item.total}`,
        14,
        y
      );
      y += 10;
    });

    doc.save("invoice.pdf");
  };

  // Function to toggle payment status
  const togglePaymentStatus = () => {
    setIsModalOpen(true);
  };

  // Function to confirm payment status change
  const confirmPaymentStatus = () => {
    setPaymentStatus("Paid");
    setIsModalOpen(false);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Cleanup on unmount
    };
  }, [isModalOpen]);
  return (
    <div className=" w-full">
      {/* Tabs for Office, Individual, and Shop */}
      <div className="p-4 bg-slate-50 border border-dashed border-black  rounded-xl">
        <div className="flex mb-4">
          {["office", "individual", "shop"].map((tab) => (
            <button
              key={tab}
              className={`flex-1 py-2 ${
                selectedTab === tab
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              } rounded-none`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {/* Search Bar */}
        <div className="flex items-center mb-4">
          <FaSearch className="mr-2 text-gray-500" />
          <input
            type="text"
            className="flex-1 p-2 border rounded-md"
            placeholder={`Search ${selectedTab}`}
          />
        </div>

        {/* Dropdown for Selecting Customer */}
        <div className="mb-6">
          <select
            className="w-full p-2 border rounded-md"
            onChange={(e) =>
              setSelectedCustomer(
                customers.find((c) => c.id === parseInt(e.target.value))
              )
            }
            value={selectedCustomer ? selectedCustomer.id : ""}
          >
            <option value="">Select a Customer</option>
            {customers
              .filter((c) => c.type === selectedTab)
              .map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
          </select>
        </div>

        {/* Divider for Separation */}
      </div>

      {/* Show Invoice Details if a Customer is Selected */}
      {selectedCustomer && (
        <div className="bg-white border-t border-slate-200 rounded-lg shadow-lg p-4 mt-4">
          {/* Invoice Header with Company Info */}
          <div className="flex items-center justify-between mb-6">
            <div>
              <center><img src="/images/ani-ico.gif" width={50}/></center>
              <h1 className=" text-lg md:text-xl font-semibold text-blue-600">
                ChaiBook
              </h1>
            </div>
            <div className="text-end">
              <h2 className="text-2xl font-semibold text-gray-800">
                Invoice #3682303
              </h2>
              <p className="mt-1 text-gray-500">
                Billing Date: {invoiceData.billingDate}
              </p>
              <p className="text-gray-500">
                Customer: {invoiceData.customerName}
              </p>
            </div>
          </div>

          {/* Itemized Deliveries in a Professional Format */}
          <div className="mb-6">
            <h3 className="font-semibold text-lg text-gray-800 mb-2">Items:</h3>
            <div className="border border-gray-300 rounded-lg overflow-hidden">
              <div className="flex justify-between bg-gray-100 p-2 font-semibold text-gray-800">
                <span className="flex-1 text-left">Item</span>
                <span className="flex-1 text-center">Qty</span>
                <span className="flex-1 text-center">Price</span>
                <span className="flex-1 text-center">Total</span>
              </div>
              {invoiceData.deliveryRecords.map((record, index) => (
                <div
                  key={index}
                  className="flex justify-between p-2 border-b last:border-b-0"
                >
                  <span className="flex-1 text-left text-gray-700">
                    {record.itemName}
                  </span>
                  <span className="flex-1 text-center text-gray-700">
                    {record.quantity}
                  </span>
                  <span className="flex-1 text-center text-gray-700">
                    ₹{record.price}
                  </span>
                  <span className="flex-1 text-center text-gray-700">
                    ₹{record.total}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Summary Section */}
          <div className="mb-6">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-600 font-bold">Total Quantity:</span>
              <span className="font-semibold">
                {invoiceData.deliveryRecords.reduce(
                  (sum, record) => sum + record.quantity,
                  0
                )}
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-600 font-bold">Total Price:</span>
              <span className="font-semibold">
                <FaRupeeSign className="inline" />{" "}
                {invoiceData.deliveryRecords.reduce(
                  (sum, record) => sum + record.total,
                  0
                )}
              </span>
            </div>
            <div className="flex justify-between items-center text-lg font-bold border-t pt-2 mt-2">
              <span>Grand Total:</span>
              <span>
                <FaRupeeSign className="inline" />{" "}
                {invoiceData.deliveryRecords.reduce(
                  (sum, record) => sum + record.total,
                  0
                )}
              </span>
            </div>
          </div>

          {/* Payment Status */}
          <div className="flex justify-between items-center mb-6">
            <div
              className={`flex items-center text-lg font-bold ${
                paymentStatus === "Paid" ? "text-green-600" : "text-red-600"
              }`}
            >
              {paymentStatus === "Paid" ? (
                <FaCheckCircle className="mr-2" />
              ) : (
                <FaTimesCircle className="mr-2" />
              )}
              {paymentStatus}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={togglePaymentStatus}
              className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md mr-2"
            >
              Mark as Paid
            </button>
            <button
              onClick={generatePDF}
              className="bg-gray-500 text-white px-4 py-2 rounded-md shadow-md"
            >
              Print Invoice
            </button>
          </div>
             {/* Footer */}
      <div className="mt-6 text-center text-gray-500 text-sm">
        © 2024 ChaiBook. All Rights Reserved.
      </div>
        </div>
      )}
<br></br>
      {/* Modal for Confirmation */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-end justify-center z-50">
          <div className="bg-white rounded-t-lg w-full max-w-lg shadow-lg p-6 transition-all duration-300 relative z-10">
            <h2 className="text-xl font-semibold mb-4">Confirm Payment</h2>
            <p className="mb-4">
              Once marked as paid, this cannot be changed. Are you sure you want
              to proceed?
            </p>
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="text-gray-500 border border-gray-500 px-4 py-2 rounded-md mr-2"
              >
                Cancel
              </button>
              <button
                onClick={confirmPaymentStatus}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Mark as Paid
              </button>
            </div>
          </div>
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
        </div>
      )}

      {/* Footer */}
      <div className="mt-6 text-center text-gray-500 text-sm">
        © 2024 ChaiBook. All Rights Reserved.
      </div>
    </div>
  );
};

export default InvoiceComponent;
