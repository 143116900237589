import React from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { FaCloudUploadAlt } from "react-icons/fa";

const BackupPage = () => {
  const navigate = useNavigate();

  // Function to handle going back to the previous page
  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <div className="p-4 min-h-screen">
      {/* Back Button at the Top */}
      <div className="flex items-center mb-6">
        <button
          onClick={handleBack}
          className="flex items-center text-blue-500 mr-4"
        >
          <FaChevronLeft size={20} />
          <span className="ml-1">Back</span>
        </button>
      </div>

      {/* Screen Title */}
      <h1 className="text-2xl font-semibold mb-4">Backup</h1>

      {/* Backup Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        {/* Backup Icon */}
        <div className="flex justify-center mb-4">
          <FaCloudUploadAlt size={50} className="text-blue-500" />
        </div>
        {/* Backup Description */}
        <p className="text-center text-gray-700 mb-6">
          Backup your data to the cloud to keep it safe and easily recoverable.
        </p>

        {/* Backup Button */}
        <button className="w-full bg-blue-500 text-white py-3 rounded-lg font-medium hover:bg-blue-600 transition duration-200 mb-4">
          Backup Now
        </button>

        {/* Last Backup Info */}
        <div className="text-center text-gray-500 text-sm">
          <p>Last Backup: October 20, 2024, 10:30 AM</p>
        </div>
      </div>

      {/* Backup Settings */}
      <div className="mt-8 bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-medium mb-4">Backup Settings</h2>
        <div className="flex justify-between items-center py-2">
          <span className="text-gray-700">Automatic Backup</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-500"></div>
          </label>
        </div>
        <div className="flex justify-between items-center py-2">
          <span className="text-gray-700">Backup Over Wi-Fi Only</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-500"></div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default BackupPage;
