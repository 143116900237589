// src/components/AuthWarningPopup.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AuthWarningPopup = ({ onClose }) => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg text-center max-w-md mx-auto">
        <h2 className="text-lg font-semibold text-red-600">Warning!</h2>
        <p className="mt-2 text-gray-700">You are not authenticated. Please log in to continue.</p>
        <div className="mt-4">
          <button
            onClick={() => {
              onClose();
              navigate('/login'); // Navigate to login page
            }}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 transition"
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthWarningPopup;
