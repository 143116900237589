import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useGetWorkers = (status) => {
  return useQuery({
    queryKey:["getWorkers",status],
    queryFn:async()=>{
        const response = await axiosInstance.get(`/outlet/worker/getworkers/${status}`);
        if(!response.data)
        {
            throw new Error('error while fetching Workers');
        }
        return response.data;
    }, refetchOnWindowFocus: false,staleTime: 5 * 60 * 1000, retry: 1,

  });
};
export default useGetWorkers;