import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Bar, Pie, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, PointElement, LineElement, Tooltip, Legend);

const ReportsComponent = () => {
  const [selectedTab, setSelectedTab] = useState('sales');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Dummy data for customers
  const customers = [
    { id: 1, name: 'ABC Office Solutions' },
    { id: 2, name: 'John Doe' },
  ];

  // Dummy report data for each customer
  const reportsData = {
    1: {
      sales: [120, 150, 180, 200, 170, 220],
      deliveries: [50, 70, 90, 110, 80, 130],
      payments: [80, 120, 140, 160, 130, 190],
    },
    2: {
      sales: [100, 130, 160, 180, 140, 210],
      deliveries: [30, 60, 80, 100, 70, 110],
      payments: [70, 110, 130, 150, 120, 180],
    },
  };

  // Chart data configuration
  const barChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales',
        data: selectedCustomer ? reportsData[selectedCustomer.id].sales : [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Deliveries',
        data: selectedCustomer ? reportsData[selectedCustomer.id].deliveries : [],
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
    ],
  };

  const pieChartData = {
    labels: ['Paid', 'Pending'],
    datasets: [
      {
        data: selectedCustomer
          ? [reportsData[selectedCustomer.id].payments.reduce((a, b) => a + b, 0), 500]
          : [],
        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
      },
    ],
  };

  const lineChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Payments',
        data: selectedCustomer ? reportsData[selectedCustomer.id].payments : [],
        fill: false,
        backgroundColor: 'rgba(255, 206, 86, 0.6)',
        borderColor: 'rgba(255, 206, 86, 1)',
      },
    ],
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Tabs for Sales, Deliveries, and Payments */}
      <div className="flex mb-4">
        {['sales', 'deliveries', 'payments'].map((tab) => (
          <button
            key={tab}
            className={`flex-1 py-2 ${
              selectedTab === tab ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            } rounded-none`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {/* Search Bar */}
      <div className="flex items-center mb-4">
        <FaSearch className="mr-2 text-gray-500" />
        <input
          type="text"
          className="flex-1 p-2 border rounded-md"
          placeholder="Search Reports"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Dropdown for Selecting Customer */}
      <div className="mb-6">
        <select
          className="w-full p-2 border rounded-md"
          onChange={(e) =>
            setSelectedCustomer(customers.find((c) => c.id === parseInt(e.target.value)))
          }
          value={selectedCustomer ? selectedCustomer.id : ''}
        >
          <option value="">Select a Customer</option>
          {customers.map((customer) => (
            <option key={customer.id} value={customer.id}>
              {customer.name}
            </option>
          ))}
        </select>
      </div>

      {/* Divider */}
      <div className="my-4 border-t border-dotted border-gray-300"></div>

      {/* Report Section */}
      {selectedCustomer && (
        <div>
          {/* Charts */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            {/* Bar Chart */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold mb-4">Sales and Deliveries</h2>
              <Bar data={barChartData} />
            </div>
            {/* Pie Chart */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold mb-4">Payment Status</h2>
              <Pie data={pieChartData} />
            </div>
            {/* Line Chart */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold mb-4">Monthly Payments</h2>
              <Line data={lineChartData} />
            </div>
          </div>

          {/* Summary Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold">Total Deliveries</h3>
              <p className="text-2xl">
                {reportsData[selectedCustomer.id].deliveries.reduce((a, b) => a + b, 0)}
              </p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold">Total Sales</h3>
              <p className="text-2xl">
                {reportsData[selectedCustomer.id].sales.reduce((a, b) => a + b, 0)}
              </p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold">Total Payments</h3>
              <p className="text-2xl">
                {reportsData[selectedCustomer.id].payments.reduce((a, b) => a + b, 0)}
              </p>
            </div>
          </div>
        </div>
      )}
      <br/><br/>
    </div>
  );
};

export default ReportsComponent;
