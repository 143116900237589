import React, { useState } from 'react';
import { FaFilter, FaSearch, FaEye, FaUserPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const OrdersPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('All');

    // Dummy orders data for illustration
    const orders = [
        {
            id: 1,
            items: 'Masala Tea, Sandwich',
            status: 'Delivered',
            amount: 150,
            quantity: 2,
            size: 'Large',
            deliveryBoy: 'Rajesh',
            customer: 'Office A'
        },
        {
            id: 2,
            items: 'Espresso, Cake',
            status: 'Pending',
            amount: 80,
            quantity: 1,
            size: 'Medium',
            deliveryBoy: 'Anil',
            customer: 'Shop B'
        },
        {
            id: 3,
            items: 'Green Tea',
            status: 'Cancelled',
            amount: 40,
            quantity: 1,
            size: 'Small',
            deliveryBoy: 'Suresh',
            customer: 'John Doe'
        },
        {
            id: 4,
            items: 'Black Coffee, Croissant',
            status: 'Delivered',
            amount: 95,
            quantity: 1,
            size: 'Large',
            deliveryBoy: 'Rahul',
            customer: 'Jane Smith'
        },
    ];

    // Filter orders based on search term and filter type
    const filteredOrders = orders.filter(order => 
        (filter === 'All' || order.status === filter) &&
        (order.customer.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const navigate = useNavigate();

    const handleViewOrderDetails = (id) => {
        navigate(`${id}`);
    };

    return (
        <div className="p-4">
            <div className="flex items-center justify-between mb-3">
                <h5 className="text-lg font-semibold">View Customers</h5>
                <button 
                    onClick={()=>{navigate("newdelivery")}}
                    className="flex items-center gap-1 bg-blue-500 text-white px-3 py-1 rounded-md text-sm hover:bg-blue-600"
                >
                    <FaUserPlus />
                    New Delivery
                </button>
            </div>

            {/* Filter Section */}
            <div className="sticky top-0 bg-white z-10 p-3 mb-4 border-b">
                <div className="flex gap-4 w-full">
                    {/* Tab for Order Status Filter */}
                    <button 
                        className={`flex-1 text-sm p-2 ${filter === 'All' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'} hover:text-blue-500`}
                        onClick={() => setFilter('All')}
                    >
                        All
                    </button>
                    <button 
                        className={`flex-1 text-sm p-2 ${filter === 'Delivered' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'} hover:text-blue-500`}
                        onClick={() => setFilter('Delivered')}
                    >
                        Delivered
                    </button>
                    <button 
                        className={`flex-1 text-sm p-2 ${filter === 'Pending' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'} hover:text-blue-500`}
                        onClick={() => setFilter('Pending')}
                    >
                        Pending
                    </button>
                    <button 
                        className={`flex-1 text-sm p-2 ${filter === 'Cancelled' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'} hover:text-blue-500`}
                        onClick={() => setFilter('Cancelled')}
                    >
                        Cancelled
                    </button>
                </div>

                {/* Search Bar Below the Tabs */}
                <div className="flex items-center gap-2 w-full sm:w-1/2 mt-3">
                    <FaSearch className="text-gray-600" />
                    <input 
                        type="text"
                        placeholder="Search customers..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="p-1 border rounded-md w-full text-sm"
                    />
                </div>
            </div>

            {/* Orders List */}
            <div className="divide-y divide-gray-200">
                {filteredOrders.length > 0 ? (
                    filteredOrders.map((order) => (
                        <div 
                            key={order.id} 
                            className="flex items-start justify-between gap-4 py-3"
                        >
                            <div className="flex-1">
                                <h3 className="font-semibold text-sm">Order ID: #{order.id}</h3>
                                <p className="text-xs text-gray-500">Items: {order.items}</p>
                                <p className="text-xs text-gray-500">Customer: {order.customer}</p>
                                <p className="text-xs text-gray-500">Delivery Boy: {order.deliveryBoy}</p>
                            </div>
                            <div className="flex-none text-right">
                                <p className="text-xs text-gray-500">Status: <span className={order.status === 'Delivered' ? 'text-green-500' : order.status === 'Pending' ? 'text-yellow-500' : 'text-red-500'}>{order.status}</span></p>
                                <p className="text-xs text-gray-500">Quantity: {order.quantity}</p>
                                <p className="text-xs text-gray-500">Size: {order.size}</p>
                                <p className="text-xs text-gray-500">Amount: ₹{order.amount}</p>
                                <button 
                                    className="text-blue-500 hover:underline text-sm mt-2"
                                    onClick={() => handleViewOrderDetails(order.id)}
                                >
                                    <FaEye /> View Details
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center text-gray-500 py-4">
                        No orders found.
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrdersPage;
