import React from 'react'

function Customer() {
  return (
    <div>
      customers
    </div>
  )
}

export default Customer
