import React, { useState } from 'react';
import { FaUser, FaRupeeSign } from 'react-icons/fa'; // User and Rupee icons

const OrdersTab = () => {
    // Sample dummy data for orders
    const ordersData = [
        {
            orderId: 1,
            date: '2024-10-15',
            deliveryBoy: 'Ravi Kumar',
            items: [
                { name: 'Tea', size: '200ml', price: 50, quantity: 2 },
                { name: 'Coffee', size: '300ml', price: 70, quantity: 1 },
            ],
            paymentStatus: 'Paid',
        },
        {
            orderId: 2,
            date: '2024-10-16',
            deliveryBoy: 'Anita Sharma',
            items: [
                { name: 'Green Tea', size: '250ml', price: 60, quantity: 3 },
                { name: 'Black Coffee', size: '250ml', price: 80, quantity: 2 },
            ],
            paymentStatus: 'Pending',
        },
        {
            orderId: 3,
            date: '2024-10-17',
            deliveryBoy: 'Raj Singh',
            items: [
                { name: 'Masala Tea', size: '200ml', price: 55, quantity: 1 },
                { name: 'Espresso', size: '100ml', price: 100, quantity: 1 },
            ],
            paymentStatus: 'Paid',
        },
    ];

    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('');

    // Filter and search logic
    const filteredOrders = ordersData.filter(order => {
        const orderMatches = order.orderId.toString().includes(searchTerm) || 
                             order.items.some(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        return orderMatches && (filter === '' || (filter === 'completed' && order.paymentStatus === 'Paid') || (filter === 'pending' && order.paymentStatus === 'Pending'));
    });

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    return (
        <div className="p-4">
            {/* Search and Filter */}
            <div className="mb-4 flex flex-col md:flex-row gap-2">
                <input 
                    type="text" 
                    placeholder="Search by Order ID or Item Name" 
                    value={searchTerm} 
                    onChange={handleSearchChange} 
                    className="border rounded-lg p-2 w-full md:w-1/3"
                />
                <select 
                    value={filter} 
                    onChange={handleFilterChange} 
                    className="border rounded-lg p-2 w-full md:w-1/4"
                >
                    <option value="">All Orders</option>
                    <option value="completed">Completed</option>
                    <option value="pending">Pending</option>
                </select>
            </div>

            {/* Order Details */}
            {filteredOrders.map(order => {
                const totalAmount = order.items.reduce((total, item) => total + (item.price * item.quantity), 0);
                return (
                    <div key={order.orderId} className="bg-white p-4 rounded-lg shadow-lg mb-4">
                        <div className="flex items-center justify-between">
                            <h5 className="font-semibold text-gray-800">Order #{order.orderId}</h5>
                            <p className="text-sm text-gray-500">{order.date}</p>
                        </div>
                        <hr className="my-2" />
                        <div className="flex items-center mb-2">
                            <FaUser className="text-gray-500 mr-2" />
                            <span className="font-medium text-gray-700">Delivery Boy: {order.deliveryBoy}</span>
                        </div>
                        <h6 className="mt-2 font-semibold">Order Items:</h6>
                        <ul className="mt-2 space-y-1">
                            {order.items.map((item, index) => (
                                <li key={index} className="flex justify-between items-center py-1 text-sm">
                                    <div>
                                        <span className="font-medium">{item.name}</span> - {item.size}
                                    </div>
                                    <div className="text-right">
                                        <span>₹{item.price} x {item.quantity} = ₹{item.price * item.quantity}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <hr className="my-2" />
                        <div className="flex justify-between items-center">
                            <span className="font-semibold text-gray-800">Total Amount:</span>
                            <span className="text-blue-600 flex items-center">
                                <FaRupeeSign className="mr-1" />{totalAmount}
                            </span>
                        </div>
                        <p className={`mt-2 text-sm font-medium ${order.paymentStatus === 'Paid' ? 'text-green-500' : 'text-red-500'}`}>
                            Payment Status: {order.paymentStatus}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default OrdersTab;
