import { useMutation } from '@tanstack/react-query';
import axiosInstance from "../../utils/axiosInstance";

const useAddItem = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosInstance.post("/outlet/item/additem", data);
      if (!response.data) {
        throw new Error("add item failed");
      }
      return response.data;
    },
  });
};
export default useAddItem;
