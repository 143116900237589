import { useEffect, useState } from 'react';
import { FaSearch, FaEye, FaUserPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import AddNewCustomer from './AddNewCustomer';
import useGetCustomers from '../../../../hooks/customers/getCustomers';

const ViewCustomers = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('All');
    const [customers,setCustomers] = useState([])
    
    const filteredCustomers = customers?.filter(customer => 
        (filter === 'All' || customer.type === filter) &&
        (customer?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer?.contact?.email.toLowerCase().includes(searchTerm.toLowerCase())||
        customer?.contact?.phone.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    // Handler for viewing customer details
    const handleViewOrderHistory = (customer) => {
        navigate(customer._id); 
    };

    const {data,isloading} = useGetCustomers();
    useEffect(() => {
        if (data) {
            setCustomers(data.customersdata);
            console.log(data.customersdata);
            
        }
    }, [data]);
    

    return (
        <div className="p-4">
            {<AddNewCustomer isOpen={isModalOpen} onClose={closeModal}/>}
            <div className="flex items-center justify-between mb-3">
                <h5 className="text-lg font-semibold">View Customers</h5>
                <button 
                    onClick={openModal}
                    className="flex items-center gap-1 bg-blue-500 text-white px-3 py-1 rounded-md text-sm hover:bg-blue-600"
                >
                    <FaUserPlus />
                    Add Customer
                </button>
            </div>

            {/* Filter Section with Tabs */}
            <div className="sticky -top-4    bg-white z-10 p-3 mb-4 border-b">
                <div className="flex gap-4 w-full">
                    {/* Tab for Customer Type Filter */}
                    <button 
                        className={`flex-1 text-sm p-2 ${filter === 'All' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'} hover:text-blue-500`}
                        onClick={() => setFilter('All')}
                    >
                        All
                    </button>
                    <button 
                        className={`flex-1 text-sm p-2 ${filter === 'office' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'} hover:text-blue-500`}
                        onClick={() => setFilter('office')}
                    >
                        Offices
                    </button>
                    <button 
                        className={`flex-1 text-sm p-2 ${filter === 'shop' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'} hover:text-blue-500`}
                        onClick={() => setFilter('shop')}
                    >
                        Shops
                    </button>
                    <button 
                        className={`flex-1 text-sm p-2 ${filter === 'Individual' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'} hover:text-blue-500`}
                        onClick={() => setFilter('Individual')}
                    >
                        Individuals
                    </button>
                </div>

                {/* Search Bar Below the Tabs */}
                <div className="flex items-center gap-2 w-full  mt-3">
                    <FaSearch className="text-gray-600" />
                    <input 
                        type="text"
                        placeholder="Search customers..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="p-1 border rounded-md w-full text-sm"
                    />
                </div>
            </div>

            {/* Customer List */}
            <div className="divide-y divide-gray-200">
                {filteredCustomers?.length > 0 ? (
                    filteredCustomers.map((customer, index) => (
                        <div 
                            key={index} 
                            className="flex items-start gap-3 py-3"
                        >
                            <div className="flex-shrink-0 bg-gray-200 rounded-full p-2 text-center">
                                <span className="text-lg text-gray-700">
                                    {customer.name.charAt(0).toUpperCase()}
                                </span>
                            </div>
                            {/* Customer Details */}
                            <div className="flex-1">
                                <h3 className="font-semibold text-sm">{customer?.name}</h3>
                                <p className="text-xs text-gray-500">{customer.type}</p>
                                <p className="text-xs text-gray-500">{customer?.contact?.phone}</p>
                            </div>
                            {/* Action Buttons */}
                            <div className="flex flex-col gap-2 ml-auto">
                                <button 
                                    className="text-blue-500 hover:underline text-sm flex items-center gap-1"
                                    onClick={() => handleViewOrderHistory(customer)}
                                >
                                    <FaEye />
                                    View
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center text-gray-500 py-4">
                        No customers found.
                    </div>
                )}
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    );
};

export default ViewCustomers;
