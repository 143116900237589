import React from "react";
import {
  FaArrowLeft,
  FaEdit,
  FaUser,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import useGetSingleCustomer from "../../../../hooks/customers/getSingleCustomer";

const WorkerProfile = () => {
  const { uid } = useParams();

  const { data, isLoading } = useGetSingleCustomer(uid);
  const worker = data?.worker;
  const navigate = useNavigate();
  const onBack = () => navigate(-1);

  const onEdit = () => {
    alert("edit click");
  };
  return (
    <div className="">
      {/* Top Section: Back Button, Title, and Edit Button */}
      <div className="flex items-center justify-between mb-6">
        <button onClick={onBack} className="text-gray-600 hover:text-gray-800">
          <FaArrowLeft size={20} />
        </button>
        <h1 className="text-xl font-semibold text-gray-700">Worker Profile</h1>
        <button
          onClick={onEdit}
          className="text-blue-500 hover:text-blue-600"
        ></button>
      </div>

      {/* Profile Card */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6 flex items-center">
        {/* Profile Image */}
        <div className="flex-shrink-0 mr-4">
          <img
            src="/images/worker-profile.jpg"
            alt="Worker"
            className="w-20 h-20 rounded-full object-cover"
          />
        </div>

        {/* Worker Details */}
        <div className="flex-grow">
          <h2 className="text-lg font-semibold text-gray-700">John Doe</h2>
          <p className="text-gray-500">Phone: +123456789</p>
          <p className="text-gray-500">Email: johndoe@example.com</p>
        </div>

        {/* Edit Button */}
        <button
          onClick={onEdit}
          className="text-blue-500 hover:text-blue-600 ml-4"
        >
          <FaEdit size={18} />
        </button>
      </div>

      {/* Orders History Section */}
      <div className="bg-white rounded-lg shadow-md p-4">
        {/* Search and Filters */}
        <div className="sticky top-0 bg-white z-10 p-4 border-b">
          <input
            type="text"
            placeholder="Search orders..."
            className="w-full p-2 border rounded-md mb-4"
          />
          <div className="flex justify-between">
            <button className="text-sm px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
              Filter by Date
            </button>
            <button className="text-sm px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
              Filter by Status
            </button>
          </div>
        </div>

        {/* Order List */}
        <div className="mt-4 space-y-4">
          {/* Example order item */}
          <div className="p-4 bg-gray-50 rounded-md shadow-sm flex justify-between items-center">
            <div>
              <h3 className="text-md font-semibold text-gray-700">
                Order #1234
              </h3>
              <p className="text-sm text-gray-500">Delivered on Oct 20, 2024</p>
            </div>
            <span className="text-green-500 font-bold">Delivered</span>
          </div>
          <div className="p-4 bg-gray-50 rounded-md shadow-sm flex justify-between items-center">
            <div>
              <h3 className="text-md font-semibold text-gray-700">
                Order #1235
              </h3>
              <p className="text-sm text-gray-500">Delivered on Oct 22, 2024</p>
            </div>
            <span className="text-green-500 font-bold">Delivered</span>
          </div>
          {/* Repeat above block for more orders */}
        </div>
      </div>
    </div>
  );
};

export default WorkerProfile;
