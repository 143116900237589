import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaEnvelope,
  FaPhone,
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaGithub,
  FaPaperPlane,
  FaUser,
} from "react-icons/fa";

const SupportAboutUs = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const handleSendMessage = () => {
    if (message.trim() === "") {
      alert("Please enter a message.");
      return;
    }
    // Logic to handle sending the message can be added here (e.g., API call)
    alert(`Message sent: ${message}`);
    setMessage(""); // Clear the input after sending
  };

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="p-2 min-h-screen flex flex-col items-center">
      {/* Back Button at the Top */}
      <div className="w-full  mb-4">
        <button
          onClick={handleBack}
          className="text-blue-600 hover:text-blue-800 flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 mr-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          Back
        </button>
      </div>

      <div className="w-full border border-black  bg-white  rounded-lg p-6 mb-8">
        {/* Header */}
        <h2 className="text-2xl font-bold text-center mb-4 text-blue-600">
          About Us
        </h2>
        <p className="text-center text-gray-600 mb-6">
          We are Buildogic, a tech-driven company focusing on delivering
          high-quality web development services. Our mission is to empower
          businesses with modern web solutions.
        </p>

        {/* Contact Details */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-blue-600 mb-2">
            Contact Information
          </h3>
          <div className="flex items-center mb-3">
            <FaUser className="text-blue-500 mr-2" />
            <span className="text-gray-700">Anil Jadav</span>
          </div>
          <div className="flex items-center mb-3">
            <FaEnvelope className="text-blue-500 mr-2" />
            <span className="text-gray-700">founder@buildogic.com</span>
          </div>
          <div className="flex items-center mb-3">
            <FaPhone className="text-blue-500 mr-2" />
            <span className="text-gray-700">+91 8849456821</span>
          </div>
          <div className="flex items-center mb-3">
            <FaEnvelope className="text-blue-500 mr-2" />
            <span className="text-gray-700">www.buildogic.com</span>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-blue-600 mb-2">
            Follow Us
          </h3>
          <div className="flex justify-around">
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-blue-500"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-blue-500"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-blue-500"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-blue-500"
            >
              <FaLinkedin size={24} />
            </a>
            <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-blue-500"
            >
              <FaGithub size={24} />
            </a>
          </div>
        </div>

        {/* Send Message Section */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-blue-600 mb-2">
            Send Us a Message
          </h3>
          <div className="flex flex-col border rounded-lg p-4 shadow-sm">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Write your message here..."
              className="mb-4 p-2 text-gray-700 rounded-lg border border-gray-300 outline-none"
            />
            <button
              onClick={handleSendMessage}
              className="flex items-center justify-center w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              <FaPaperPlane className="mr-2" />
              Send
            </button>
          </div>
        </div>

        {/* Support Section */}
        <div className="text-center">
          <h3 className="text-xl font-semibold text-blue-600 mb-2">Support</h3>
          <p className="text-gray-700 mb-4">
            If you need any help, feel free to contact us at the email or phone
            number above. We are here to assist you!
          </p>
        </div>
      </div>

      {/* Footer */}
      <footer className="mt-auto text-center text-gray-500 text-sm">
        © 2024 Buildogic. All rights reserved.
      </footer>
    </div>
  );
};

export default SupportAboutUs;
