import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaTimes, FaTag, FaRupeeSign, FaBalanceScale } from "react-icons/fa";

import useAddItem from "../../../../hooks/items/addItem";
import { QueryClient, useQueryClient } from "@tanstack/react-query";

const AddNewItem = ({ isOpen, onClose }) => {
  const sizes = ["ml", "mg", "g", "kg", "pcs"];
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchCurrentY, setTouchCurrentY] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [formData, setFormData] = useState({
    itemName: "",
    price: "",
    size: "",
    unit: "",
  });

  const [showWarning, setShowWarning] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [countdown, setCountdown] = useState(5); // Countdown timer

  // Disable background scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Cleanup on unmount
    };
  }, [isOpen]);

  // Check if there are unsaved changes
  useEffect(() => {
    const { itemName, price, size, unit } = formData;
    if (itemName || price || size) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [formData]);

  // Detect swipe down to close the modal
  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setTouchCurrentY(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (touchStartY && touchCurrentY && touchCurrentY - touchStartY > 100) {
      // If swipe distance is greater than 100px, check for unsaved changes
      if (hasUnsavedChanges) {
        setShowWarning(true); // Show the warning modal if there are unsaved changes
      } else {
        closeWithAnimation(); // Close the modal without showing the warning
      }
    } else {
      setTouchCurrentY(null);
    }
    setTouchStartY(null);
  };

  // Close the modal with animation

  // Handle form submission
  const { mutate } = useAddItem();
  const qryclient = useQueryClient();
  const handleAddItem = () => {
    mutate(
      {
        name: formData.itemName,
        price: formData.price,
        size: formData.size,
        unit: formData.unit,
      },
      {
        onSuccess: (data) => {
          console.log(data);
          toast.success("item Added Succesfully..");
          qryclient.invalidateQueries(["getItems"]);
          handleClearForm()
          closeWithAnimation()
        },
        onError: (err) => {
          console.log(err);

          toast.error(err.response.data.message || "Server Error");
        },
      }
    );
  };

  // Handle form clearing
  const handleClearForm = () => {
    setFormData({
      itemName: "",
      price: "",
      size: "",
      unit: "ml",
    });
  };

  // Update form data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Reset the countdown whenever the modal opens
  useEffect(() => {
    if (isOpen) {
      setCountdown(5); // Reset countdown to 5 when modal opens
    }
  }, [isOpen]);

  // Manage countdown and warning logic
  useEffect(() => {
    if (showWarning && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear timer when the warning closes
    } else if (countdown === 0) {
      setShowWarning(false); // Hide the warning after countdown
      setCountdown(5); // Reset countdown to 5
    }
  }, [showWarning, countdown]);

  // Close with confirmation and reset everything
  const handleConfirmClose = () => {
    setShowWarning(false);
    setCountdown(5); // Reset countdown after closing
    closeWithAnimation(); // Close the modal
  };

  // Close the modal with animation
  const closeWithAnimation = () => {
    setIsClosing(true);
    setTimeout(() => {
      handleClearForm();
      onClose(); // This is your prop function to close the modal
      setIsClosing(false);
      setShowWarning(false);
      setCountdown(5); // Reset countdown when the modal closes
    }, 300); // Duration of the closing animation
  };

  const swipeDistance =
    touchCurrentY && touchStartY
      ? Math.max(0, Math.min(touchCurrentY - touchStartY, 200))
      : 0;
  const opacity = Math.max(1 - swipeDistance / 300, 0.5);
  const translateY = swipeDistance;

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-end justify-center">
          {/* Background overlay with blur effect */}
          <div
            className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
            onClick={() => {
              if (hasUnsavedChanges) {
                setShowWarning(true);
              } else {
                closeWithAnimation();
              }
            }}
          ></div>

          {/* Modal content with swipe effect */}
          <div
            className={`relative w-full h-[70%] bg-white rounded-t-3xl shadow-lg p-6 transition-transform duration-300 ease-in-out`}
            style={{
              maxWidth: "100%",
              opacity: isClosing ? 0 : opacity,
              transform: isClosing
                ? "translateY(100%)"
                : `translateY(${translateY}px)`,
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {/* Modal Title */}
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-semibold">Add New Item</h2>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => {
                  if (hasUnsavedChanges) {
                    setShowWarning(true);
                  } else {
                    closeWithAnimation();
                  }
                }}
              >
                <FaTimes size={24} />
              </button>
            </div>

            {/* Warning message */}
            {showWarning && (
              <div className="bg-slate-100 rounded-lg p-2 mb-4 text-red-600 flex justify-between items-center">
                <p>You have unsaved changes. Closing in {countdown} seconds</p>
                <button
                  onClick={() => {
                    handleConfirmClose();
                  }}
                  className="border border-red-500 text-red-500 px-2 py-1 rounded hover:bg-red-100 ml-2"
                >
                  Confirm
                </button>
              </div>
            )}

            {/* Form Fields */}
            <div className="space-y-4">
              {/* Item Name Input */}
              <div className="flex items-center space-x-2">
                <FaTag />
                <input
                  type="text"
                  name="itemName"
                  placeholder="Item Name"
                  value={formData.itemName}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              {/* Price Input */}
              <div className="flex items-center space-x-2">
                <FaRupeeSign />
                <input
                  type="text"
                  name="price"
                  placeholder="Price"
                  value={formData.price}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              {/* Size Input */}
              <div className="flex items-center space-x-2">
                <FaBalanceScale />
                <input
                  type="text"
                  name="size"
                  placeholder="Size"
                  value={formData.size}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              {/* Unit Input */}
              <div className="flex items-center space-x-2">
                <FaBalanceScale />
                <select
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                >
                  <option>please select</option>
                  {sizes.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-between mt-6">
              <button
                onClick={handleClearForm}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                Clear
              </button>
              <button
                onClick={handleAddItem}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewItem;
