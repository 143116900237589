import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaShoppingCart, FaUser, FaTag, FaMoneyBillWave } from 'react-icons/fa';

const ViewOrderDetails = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();

    // Dummy data for order details (replace with actual data from your database)
    const orderDetails = {
        id: orderId,
        items: [
            { name: 'Masala Tea', quantity: 2, size: 'Large', price: 50 },
            { name: 'Sandwich', quantity: 1, size: 'Medium', price: 100 },
        ],
        deliveryBoy: 'Rajesh',
        customer: 'Office A',
        status: 'Delivered',
        totalAmount: 200,
        orderDate: '2024-10-18',
        deliveryAddress: '123 Main St, City, Country',
    };

    const handleBack = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <div className="p-4">
            {/* Header with Back Button */}
            <div className="flex items-center mb-4">
                <button onClick={handleBack} className="flex items-center text-blue-500 hover:underline">
                    <FaArrowLeft className="mr-2" /> Back
                </button>
                <h5 className="text-lg font-semibold ml-4">Order Details</h5>
            </div>

            {/* Invoice Section */}
            <div className="border rounded-md p-4 bg-white shadow-md">
                <h6 className="font-semibold text-lg mb-2"><FaShoppingCart className="inline mr-1" /> Invoice #{orderDetails.id}</h6>
                <p className="text-sm text-gray-500 mb-4">Order Date: {orderDetails.orderDate}</p>

                <div className="mb-4">
                    <h6 className="font-semibold text-md"><FaUser className="inline mr-1" /> Customer:</h6>
                    <p className="text-sm">{orderDetails.customer}</p>
                </div>

                {/* Items Ordered Section */}
                <h6 className="font-semibold text-md mb-2"><FaTag className="inline mr-1" /> Items Ordered:</h6>
                {orderDetails.items.map((item, index) => (
                    <div key={index} className="border-b border-gray-200 py-2">
                        <div className="flex justify-between">
                            <span className="font-semibold">{item.name}</span>
                            <span>₹{item.price}</span>
                        </div>
                        <div className="flex justify-between text-sm text-gray-600">
                            <span>Size: {item.size}</span>
                            <span>Quantity: {item.quantity}</span>
                        </div>
                        <div className="flex justify-between text-sm text-gray-600">
                            <span>Total: ₹{item.quantity * item.price}</span>
                        </div>
                    </div>
                ))}

                {/* Grand Total Section */}
                <div className="mt-4 flex justify-between font-semibold">
                    <span className="text-lg">Total Amount:</span>
                    <span className="text-lg">₹{orderDetails.totalAmount}</span>
                </div>

                <div className="mb-4 mt-6">
                    <h6 className="font-semibold text-md"><FaUser className="inline mr-1" /> Delivered By:</h6>
                    <p className="text-sm">{orderDetails.deliveryBoy}</p>
                </div>

                <div className="mb-4">
                    <h6 className="font-semibold text-md"><FaMoneyBillWave className="inline mr-1" /> Status:</h6>
                    <p className={`text-sm ${orderDetails.status === 'Delivered' ? 'text-green-500' : orderDetails.status === 'Pending' ? 'text-yellow-500' : 'text-red-500'}`}>
                        {orderDetails.status}
                    </p>
                </div>

                <div className="mb-4">
                    <h6 className="font-semibold text-md"><FaTag className="inline mr-1" /> Delivery Address:</h6>
                    <p className="text-sm">{orderDetails.deliveryAddress}</p>
                </div>
            </div>
        </div>
    );
};

export default ViewOrderDetails;
