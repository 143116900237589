import React, { useState } from 'react';
import { FaRupeeSign, FaCalendarDay, FaCheckCircle, FaTimesCircle, FaFilter } from 'react-icons/fa';

const PaymentHistory = () => {
    // Sample dummy data for payment history
    const paymentData = [
        { id: 301, date: '2024-10-01', amount: 1200, status: 'Paid' },
        { id: 302, date: '2024-10-05', amount: 800, status: 'Pending' },
        { id: 303, date: '2024-10-10', amount: 1500, status: 'Paid' },
    ];

    const [filter, setFilter] = useState(''); // For filtering payment status

    // Filter payments based on the selected filter
    const filteredPayments = paymentData.filter(payment => 
        filter === '' || payment.status.toLowerCase() === filter.toLowerCase()
    );

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    return (
        <div>
            <h3 className="font-semibold text-lg mb-4">Payment History</h3>
            
            {/* Filter Section */}
            <div className="mb-4 flex items-center">
                <FaFilter className="text-gray-500 mr-2" />
                <select 
                    value={filter} 
                    onChange={handleFilterChange} 
                    className="border rounded-lg p-2 w-full md:w-1/4"
                >
                    <option value="">All Payments</option>
                    <option value="Paid">Paid</option>
                    <option value="Pending">Pending</option>
                </select>
            </div>

            {/* Payment Cards */}
            {filteredPayments.map(payment => (
                <div key={payment.id} className="bg-white p-4 rounded-lg shadow-lg mb-4">
                    <div className="flex justify-between items-center mb-2">
                        <div className="text-gray-700 font-medium">
                            <FaCalendarDay className="inline-block mr-1 text-blue-500" />
                            <span>Payment ID: {payment.id}</span>
                        </div>
                        <div className={`text-sm font-semibold ${payment.status === 'Paid' ? 'text-green-500' : 'text-red-500'}`}>
                            {payment.status === 'Paid' ? (
                                <FaCheckCircle className="inline-block mr-1" />
                            ) : (
                                <FaTimesCircle className="inline-block mr-1" />
                            )}
                            {payment.status}
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="mb-2">
                        <p className="text-gray-500">
                            <FaCalendarDay className="inline-block mr-1 text-gray-400" /> 
                            Date: <span className="font-medium text-gray-800">{payment.date}</span>
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="font-semibold text-gray-800">Amount:</span>
                        <span className="text-blue-600 flex items-center">
                            <FaRupeeSign className="inline-block mr-1" />
                            ₹{payment.amount}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PaymentHistory;
