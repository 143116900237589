import React, { useState } from "react";
import { FaCalendarAlt, FaPlus, FaEdit, FaTrash, FaChevronLeft, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NewDeliveryPage = () => {
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const [deliveryType, setDeliveryType] = useState("");
  const [customer, setCustomer] = useState("");
  const [items, setItems] = useState([]);
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [newItem, setNewItem] = useState({ name: "", qty: 1, price: 0 });
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const navigate = useNavigate();

  const handleAddItem = () => {
    if (!newItem.name || newItem.qty <= 0) {
      alert("Please select an item and enter a valid quantity.");
      return;
    }

    // Ensure price is set based on selected item
    const itemPrices = {
      Tea: 1.5,
      Coffee: 2.0,
      Sandwich: 3.0,
      Biscuits: 0.5,
      Samosa: 1.0,
    };

    const price = itemPrices[newItem.name] || 0;
    newItem.price = price;

    if (isEditing) {
      const updatedItems = [...items];
      updatedItems[editingIndex] = newItem;
      setItems(updatedItems);
      setIsEditing(false);
      setEditingIndex(null);
    } else {
      setItems([...items, newItem]);
    }

    // Reset the newItem state
    setNewItem({ name: "", qty: 1, price: 0 });
    setShowItemPopup(false);
  };

  const handleEditItem = (index) => {
    setNewItem(items[index]);
    setIsEditing(true);
    setEditingIndex(index);
    setShowItemPopup(true);
  };

  const handleRemoveItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  const grandTotal = items.reduce(
    (total, item) => total + item.qty * item.price,
    0
  );

  return (
    <div className="p-4">
      {/* Back Button and Page Title */}
      
      <div className="flex items-center justify-between mb-3">
                <button 
                    onClick={() => navigate(-1)} 
                    className="text-blue-700 flex items-center"
                >
                    <FaArrowLeft className="mr-1" /> Back
                </button>
                <h5 className="text-lg font-semibold text-blue-500">New Delivery</h5>

            </div>
      {/* Date Input */}
      <div className="mb-4">
        <label className="block font-semibold mb-1">Date</label>
        <div className="flex items-center border rounded p-2">
          <FaCalendarAlt className="mr-2 text-gray-500" />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="w-full outline-none"
          />
        </div>
      </div>

      {/* Delivery Type Selection */}
      <div className="mb-4">
        <label className="block font-semibold mb-1">Delivery Type</label>
        <div className="border-dotted border-2 rounded p-2 flex justify-between">
          {["Office", "Individual", "Shop"].map((type) => (
            <button
              key={type}
              onClick={() => setDeliveryType(type)}
              className={`p-2 w-full ${
                deliveryType === type ? "bg-blue-500 text-white rounded" : ""
              }`}
            >
              {type}
            </button>
          ))}
        </div>
      </div>

      {/* Customer Dropdown */}
      <div className="mb-4">
        <label className="block font-semibold mb-1">Select Customer</label>
        <select
          value={customer}
          onChange={(e) => setCustomer(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Select Customer</option>
          {/* Render options dynamically based on the deliveryType */}
        </select>
      </div>

      {/* Add Item Section */}
      <div className="mb-4">
        <button
          onClick={() => setShowItemPopup(true)}
          className="w-full border border-blue-500 text-blue-500 bg-white rounded-md py-2 flex items-center justify-center hover:bg-blue-100 transition duration-200"
        >
          <FaPlus className="mr-2" /> Add Item
        </button>
      </div>

      {/* Items Summary */}
      <div
        className="mb-4 border rounded-md p-4 shadow-md"
        style={{
          background: "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
        }}
      >
        {items.length > 0 && (
          <h3 className="font-bold text-lg mb-2">Invoice Summary</h3>
        )}
        <div className="mb-4">
          {/* Summary Card Header */}
          {items.length > 0 && (
            <div className="flex justify-between font-bold p-2 border-b text-gray-600">
              <div className="w-1/12 text-center">#</div>
              <div className="w-3/12">Item</div>
              <div className="w-2/12 text-center">Qty</div>
              <div className="w-2/12 text-center">Price</div>
              <div className="w-2/12 text-center">Total</div>
              <div className="w-2/12 text-center">Actions</div>
            </div>
          )}

          {/* Summary Card Items */}
          {items.length > 0 ? (
            items.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center p-2 border-b"
              >
                <div className="w-1/12 text-center">{index + 1}</div>
                <div className="w-3/12 font-bold">{item.name}</div>
                <div className="w-2/12 text-center">{item.qty}</div>
                <div className="w-2/12 text-center font-bold">
                  {item.price.toFixed(2)}
                </div>
                <div className="w-2/12 text-center font-bold">
                  {(item.qty * item.price).toFixed(2)}
                </div>

                {/* Edit and Remove Buttons */}
                <div className="w-2/12 text-center flex justify-center space-x-2">
                  <button
                    onClick={() => handleEditItem(index)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    ✏️
                  </button>
                  <button
                    onClick={() => handleRemoveItem(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    🗑️
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="p-4 text-center text-gray-500">
              No items Selected..
            </div>
          )}

          {/* Grand Total */}
          {items.length > 0 && (
            <div className="flex justify-end font-bold mt-2">
              Grand Total: {grandTotal.toFixed(2)}
            </div>
          )}
        </div>
      </div>

      {/* Delivery and Payment Status */}
      <div className="flex mb-4 space-x-4">
        <select
          value={deliveryStatus}
          onChange={(e) => setDeliveryStatus(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Delivery Status</option>
          <option value="Pending">Pending</option>
          <option value="Completed">Completed</option>
        </select>
        <select
          value={paymentStatus}
          onChange={(e) => setPaymentStatus(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Payment Status</option>
          <option value="Paid">Paid</option>
          <option value="Unpaid">Unpaid</option>
        </select>
      </div>

      {/* Submit Button */}
      <button className="w-full p-3 bg-blue-500 text-white rounded">
        Submit Delivery
      </button>

      {/* Item Selection Popup */}
      {showItemPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md w-3/4">
            <h3 className="mb-4">{isEditing ? "Edit Item" : "Add Item"}</h3>
            <div className="mb-4">
              <select
                value={newItem.name}
                onChange={(e) =>
                  setNewItem({ ...newItem, name: e.target.value })
                }
                className="w-full p-2 border rounded"
              >
                <option value="">Select Item</option>
                <option value="Tea">Tea - $1.50</option>
                <option value="Coffee">Coffee - $2.00</option>
                <option value="Sandwich">Sandwich - $3.00</option>
                <option value="Biscuits">Biscuits - $0.50</option>
                <option value="Samosa">Samosa - $1.00</option>
              </select>
            </div>
            <div className="mb-4">
              <input
                type="number"
                value={newItem.qty}
                onChange={(e) =>
                  setNewItem({ ...newItem, qty: parseInt(e.target.value) })
                }
                className="w-full p-2 border rounded"
                placeholder="Quantity"
              />
            </div>
            <div className="flex justify-between">
              <button
                onClick={() => setShowItemPopup(false)}
                className="bg-gray-300 text-black p-2 rounded w-1/2 mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleAddItem}
                className="bg-green-500 text-white p-2 rounded w-1/2"
              >
                {isEditing ? "Update Item" : "Add Item"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewDeliveryPage;
