import { useMutation } from "@tanstack/react-query"
import axiosInstance from "../utils/axiosInstance";

const useRegister = ()=>{
    return useMutation({
        mutationFn:async (data)=>{
            const response = await axiosInstance.post('/auth/signup',data);
            if (!response.data) {
                throw new Error('Login failed. No data received.');
              }
         
              return response.data;
        },


    })
}
export default useRegister;