import React from "react";
import './spnner.css';
function Spinner() {
  return (
    <div>
      <div class="loadingio-spinner-double-ring-nq4q5u6dq7r">
        <div class="ldio-x2uulkbinbj">
          <div></div>
          <div></div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
