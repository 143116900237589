import React, { useEffect, useState } from "react";
import { FaBusinessTime } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useLogin from "../hooks/useLogin";
import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";
const ChaiBookLogin = () => {
  const navigate = useNavigate();
  const [type, settype] = useState("outlet");
  const [logged, setlogged] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      const decode = jwtDecode(localStorage.getItem("token"));
      setlogged(decode);
      console.log(decode);
    }
  }, []);
  const handleTabClick = (tab) => {
    settype(tab);
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { mutate: login, isLoading, error  } = useLogin();

  const handleLogin = (e) => {
    console.log("Login clicked", { email, password, type });

    // Call the login mutation
    login(
      { email, password, type },
      {
        onSuccess: (data) => {
          toast.success("Login successful!");
          localStorage.setItem("token", data.result.token);

          const checkTokenAndNavigate = () => {
            const token = localStorage.getItem("token");
            if (token) {
              navigate(data.result.path);
            } else {
              // Retry after a brief pause (e.g., 50 milliseconds)
              setTimeout(checkTokenAndNavigate, 50);
            }
          };

          checkTokenAndNavigate();
        },
        onError: (err) => {
          console.log(err.response.data.message);
          toast.error(err.response.data.message || "Login failed");
        },
      }
    );
  };
  return (
    <div class="font-[sans-serif]">
      <div
        style={{
          background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
          backdropFilter: "blur(10px)",
        }}
        class="grid lg:grid-cols-2 gap-4 max-lg:gap-12 bg-gradient-to-r from-blue-500 to-blue-700 px-8 py-12 h-[320px] rounded-b-3xl"
      >
        <div>
          <span className="text-xl font-extrabold text-white whitespace-nowrap flex">
            <img src="/images/ani-ico.gif" width={30} height={28} />
            CHAI BOOK
          </span>

          <div class="max-w-lg mt-16 max-lg:hidden">
            <h3 class="text-3xl font-bold text-white">Sign in</h3>
            <p class="text-sm mt-4 text-white">
              Embark on a seamless journey as you sign in to your account.
              Unlock a realm of opportunities and possibilities that await you.
            </p>
          </div>
        </div>
        <div class="bg-white shadow-lg rounded-xl sm:px-6 px-4 py-8 max-w-md w-full h-max shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] max-lg:mx-auto">
          {localStorage.getItem("token") ? (
            <><center>
              <span>
                Your Are Already Logged as{" "}
                <span className="text-green-600">
                  {logged?.firstname + " " + logged?.lastname}
                </span>
              </span>
              <br></br><br/>
        
              <button onClick={()=>{localStorage.removeItem("token"); navigate("/")}} className="px-4 ml-4  bg-transparent border rounded-md font-medium border-red-400 text-red-400">LOGOUT</button>
              <button onClick={()=>{navigate("/dashboard")}} className="px-4 ml-4  bg-transparent border rounded-md font-medium border-green-400 text-green-600">CONTINUE</button>
              </center></>
          ) : (
            <div>
              <div class="mb-4">
                <h3 class="text-3xl font-extrabold text-gray-800">Log in</h3>
              </div>

              <div className="flex">
                <div className="w-full bg-blue-500 border-2 border-blue-500 rounded-lg">
                  <div className="flex">
                    <button
                      onClick={() => handleTabClick("outlet")}
                      className={`flex py-1 w-full  text-center items-center justify-center rounded-lg ${
                        type == "outlet"
                          ? "bg-white text-blue-500 border border-white"
                          : "text-white"
                      }`}
                    >
                      <FaShop className="mr-2" />
                      Outlet
                    </button>
                    <button
                      onClick={() => handleTabClick("user")}
                      className={`flex py-1 w-full text-center items-center justify-center rounded-lg ${
                        type == "user"
                          ? "bg-white text-blue-500 border border-white"
                          : "text-white"
                      }`}
                    >
                      <FaBusinessTime className="mr-2" /> Worker
                    </button>
                  </div>
                </div>
                <button
                  type="button"
                  class="p-2 ml-2 whitespace-nowrap text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    class="inline mr-2  "
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#fbbd00"
                      d="M120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308H52.823C18.568 144.703 0 198.922 0 256s18.568 111.297 52.823 155.785h86.308v-86.308C126.989 305.13 120 281.367 120 256z"
                      data-original="#fbbd00"
                    />
                    <path
                      fill="#0f9d58"
                      d="m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216C305.044 385.147 281.181 392 256 392z"
                      data-original="#0f9d58"
                    />
                    <path
                      fill="#31aa52"
                      d="m139.131 325.477-86.308 86.308a260.085 260.085 0 0 0 22.158 25.235C123.333 485.371 187.62 512 256 512V392c-49.624 0-93.117-26.72-116.869-66.523z"
                      data-original="#31aa52"
                    />
                    <path
                      fill="#3c79e6"
                      d="M512 256a258.24 258.24 0 0 0-4.192-46.377l-2.251-12.299H256v120h121.452a135.385 135.385 0 0 1-51.884 55.638l86.216 86.216a260.085 260.085 0 0 0 25.235-22.158C485.371 388.667 512 324.38 512 256z"
                      data-original="#3c79e6"
                    />
                    <path
                      fill="#cf2d48"
                      d="m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606C388.668 26.629 324.381 0 256 0l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z"
                      data-original="#cf2d48"
                    />
                    <path
                      fill="#eb4132"
                      d="M256 120V0C187.62 0 123.333 26.629 74.98 74.98a259.849 259.849 0 0 0-22.158 25.235l86.308 86.308C162.883 146.72 206.376 120 256 120z"
                      data-original="#eb4132"
                    />
                  </svg>
                  Google
                </button>
              </div>
              <br />

              <div>
                <label class="text-gray-800 text-sm mb-2 block">
                  User name
                </label>
                <div class="relative flex items-center">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                    placeholder="Enter user name"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#bbb"
                    stroke="#bbb"
                    class="w-[18px] h-[18px] absolute right-4"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      cx="10"
                      cy="7"
                      r="6"
                      data-original="#000000"
                    ></circle>
                    <path
                      d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                      data-original="#000000"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="mt-4">
                <label class="text-gray-800 text-sm mb-2 block">Password</label>
                <div class="relative flex items-center">
                  <input
                    type="password"
                    id="password"
                    value={password}
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    class="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                    placeholder="Enter password"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#bbb"
                    stroke="#bbb"
                    class="w-[18px] h-[18px] absolute right-4 cursor-pointer"
                    viewBox="0 0 128 128"
                  >
                    <path
                      d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                      data-original="#000000"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="mt-4 text-right">
                <a
                  href="jajvascript:void(0);"
                  class="text-blue-600 text-sm font-semibold hover:underline"
                >
                  Forgot your password?
                </a>
              </div>

              <div class="mt-8">
                <button
                  type="button"
                  onClick={() => {
                    handleLogin();
                  }}
                  class="w-full shadow-xl py-3 px-6 text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                >
                  Log in
                </button>
              </div>
              <p class="text-sm mt-8 text-center text-gray-800">
                Don't have an account{" "}
                <a
                  href="javascript:void(0);"
                  class="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                >
                  Register here
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChaiBookLogin;
