import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useAddWorker = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosInstance.post(`/outlet/worker/addworker`, data);
      if (!response.data) throw new Error("failed to fetch workers data");
      return response.data;
    },
  });
};

export default useAddWorker;
