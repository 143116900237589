import React from 'react';
import Overview from './components/home/Overview';
import QuickActions from './components/home/QuickActions';
import Header from './components/Header';

const Home = () => {
  return (
    <div className="m-6">
        <Header/>
        <br/>
        <br/>
      <span className="text-xl mb-4 font-semibold mb-6">Welcome to User</span>
      <Overview/>
      <br/>
      <span className="text-xl mb-4 font-semibold mb-6">Quick Actions</span>
      <QuickActions/>
      <br/>
      <br/>
    </div>
    
  );
};

export default Home;
