import React, { useState } from "react";
import { FaArrowLeft, FaUserEdit, FaPhone, FaEnvelope, FaPencilAlt } from "react-icons/fa";
import { MdAccountBox } from "react-icons/md";

const UserProfile = () => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [userName, setUserName] = useState("John Doe");
  const [userPhone, setUserPhone] = useState("+1 234 567 890");
  const [userEmail, setUserEmail] = useState("john.doe@example.com");

  // Handlers for updating the profile fields
  const handleUpdateName = () => {
    setIsEditingName(false);
  };

  const handleUpdatePhone = () => {
    setIsEditingPhone(false);
  };

  const handleUpdateEmail = () => {
    setIsEditingEmail(false);
  };

  // Handle back button click
  const handleBack = () => {
    window.history.back();
  };

  return (
    <div className="p-4">
      {/* Back Button */}
      <button onClick={handleBack} className="mb-4 text-blue-500 flex items-center">
        <FaArrowLeft className="mr-2" />
        Back
      </button>

      {/* Profile Section */}
      <div className="flex flex-col items-center mb-6">
        <div className="relative">
          {/* Rounded Profile Image */}
          <img
            src="/images/founder.jpg"
            alt="Profile"
            className="w-24 h-24 rounded-full shadow-md"
          />
          {/* Change Image Button */}
          <button className="absolute bottom-0 right-0 bg-blue-500 p-2 rounded-full text-white shadow-md">
            <FaUserEdit />
          </button>
        </div>
      </div>

      {/* User Info Fields */}
      <div className="w-full">
        {/* Name Field */}
        <div className="flex items-center mb-4">
          <MdAccountBox className="text-gray-500 mr-2" />
          {isEditingName ? (
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="flex-grow border p-2 rounded-md"
            />
          ) : (
            <span className="flex-grow">{userName}</span>
          )}
          <button
            className="ml-2 text-blue-500"
            onClick={() => (isEditingName ? handleUpdateName() : setIsEditingName(true))}
          >
            {isEditingName ? "Update" : <FaPencilAlt />}
          </button>
        </div>

        <hr className="mb-4" />

        {/* Phone Field */}
        <div className="flex items-center mb-4">
          <FaPhone className="text-gray-500 mr-2" />
          {isEditingPhone ? (
            <input
              type="text"
              value={userPhone}
              onChange={(e) => setUserPhone(e.target.value)}
              className="flex-grow border p-2 rounded-md"
            />
          ) : (
            <span className="flex-grow">{userPhone}</span>
          )}
          <button
            className="ml-2 text-blue-500"
            onClick={() => (isEditingPhone ? handleUpdatePhone() : setIsEditingPhone(true))}
          >
            {isEditingPhone ? "Update" : <FaPencilAlt />}
          </button>
        </div>

        <hr className="mb-4" />

        {/* Email Field */}
        <div className="flex items-center">
          <FaEnvelope className="text-gray-500 mr-2" />
          {isEditingEmail ? (
            <input
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              className="flex-grow border p-2 rounded-md"
            />
          ) : (
            <span className="flex-grow">{userEmail}</span>
          )}
          <button
            className="ml-2 text-blue-500"
            onClick={() => (isEditingEmail ? handleUpdateEmail() : setIsEditingEmail(true))}
          >
            {isEditingEmail ? "Update" : <FaPencilAlt />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
