import React from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

const SettingsPage = () => {
  const navigate = useNavigate();

  // Function to handle going back to the previous page
  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <div className="p-4 min-h-screen">
      {/* Back Button at the Top */}
      <div className="flex items-center mb-6">
        <button
          onClick={handleBack}
          className="flex items-center text-blue-500 mr-4"
        >
          <FaChevronLeft size={20} />
          <span className="ml-1">Back</span>
        </button>
      </div>

      {/* Screen Title */}
      <h1 className="text-2xl font-semibold mb-4">Settings</h1>

      {/* Dummy Settings List */}
      <div className="bg-white border border-black border-dashed rounded-lg shadow-md divide-y">
        {/* Account Settings */}
        <div className="p-4">
          <h2 className="text-lg font-medium mb-2">Account</h2>
          <div className="flex justify-between items-center py-2">
            <span className="text-gray-700">Edit Profile</span>
            <button className="text-blue-500">Edit</button>
          </div>
          <div className="flex justify-between items-center py-2">
            <span className="text-gray-700">Change Password</span>
            <button className="text-blue-500">Change</button>
          </div>
        </div>

        {/* Notifications Settings */}
        <div className="p-4">
          <h2 className="text-lg font-medium mb-2">Notifications</h2>
          <div className="flex justify-between items-center py-2">
            <span className="text-gray-700">Push Notifications</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-500"></div>
            </label>
          </div>
          <div className="flex justify-between items-center py-2">
            <span className="text-gray-700">Email Notifications</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-500"></div>
            </label>
          </div>
        </div>

        {/* Privacy Settings */}
        <div className="p-4">
          <h2 className="text-lg font-medium mb-2">Privacy</h2>
          <div className="flex justify-between items-center py-2">
            <span className="text-gray-700">Location Services</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-500"></div>
            </label>
          </div>
          <div className="flex justify-between items-center py-2">
            <span className="text-gray-700">Advertising Preferences</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-500"></div>
            </label>
          </div>
        </div>

        {/* Help & Support */}
        <div className="p-4">
          <h2 className="text-lg font-medium mb-2">Help & Support</h2>
          <div className="flex justify-between items-center py-2">
            <span className="text-gray-700">FAQ</span>
            <button className="text-blue-500">View</button>
          </div>
          <div className="flex justify-between items-center py-2">
            <span className="text-gray-700">Contact Support</span>
            <button className="text-blue-500">Contact</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
