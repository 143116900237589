import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useGetSingleCustomer =(uid)=>{
    return useQuery({
        queryKey:['getSinglecustomers',uid],
        queryFn:async()=>{
            const response = await axiosInstance.get(`/outlet/customer/getcustomer/${uid}`);
            if(!response.data)
            {
                throw new Error("failed to fetch cutomers")
            }
            return response.data;
        }
    })
}

export default useGetSingleCustomer;