import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useGetBuildings =()=>{
   return useQuery({
    queryKey:["getBuildings"],
    queryFn:async()=>{
        const response = await axiosInstance('/outlet/customer/getbuildings');
        if(!response.data)
        {
            throw new Error('failed to get buildings..');
            
        }
        return response.data;
    },
    refetchOnWindowFocus: false,
   }) 
}

export default  useGetBuildings;