import Joi from "joi";

const Outlet_Signup_validation_Schema = Joi.object({
  signup_type: Joi.string().optional(),
  OutletName: Joi.string()
  .min(3)
  .pattern(/^[a-zA-Z0-9\s]+$/)
  .required()
  .messages({
    "string.base": '"Outlet Name" should be a type of string',
    "string.empty": '"Outlet Name" cannot be empty',
    "string.min": '"Outlet Name" should have a minimum length of {#limit}',
    "string.pattern.base":
      '"Outlet Name" can only contain letters, numbers, and spaces',
    "any.required": '"Outlet Name" is a required field',
  }),
  FirstName: Joi.string()
    .min(3)
    .pattern(/^[a-zA-Z\s]+$/) // Only allows alphabets and whitespace
    .required()
    .messages({
      "string.base": '"First Name" should be a type of string',
      "string.empty": '"First Name" cannot be empty',
      "string.min": '"First Name" should have a minimum length of {#limit}',
      "string.pattern.base": '"First Name" can only contain letters and spaces',
      "any.required": '"First Name" is a required field',
    }),

  LastName: Joi.string()
    .min(3)
    .pattern(/^[a-zA-Z\s]+$/) // Only allows alphabets and whitespace
    .required()
    .messages({
      "string.base": '"Last Name" should be a type of string',
      "string.empty": '"Last Name" cannot be empty',
      "string.min": '"Last Name" should have a minimum length of {#limit}',
      "string.pattern.base": '"Last Name" can only contain letters and spaces',
      "any.required": '"Last Name" is a required field',
    }),

  Email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": '"Email" must be a valid email',
      "string.empty": '"Email" cannot be empty',
      "any.required": '"Email" is a required field',
    }),

  Phone: Joi.string()
    .pattern(/^[0-9]{10}$/) // Only allows 10 digit numbers
    .required()
    .messages({
      "string.pattern.base": '"Phone" must be a 10-digit number with no spaces',
      "string.empty": '"Phone" cannot be empty',
      "any.required": '"Phone" is a required field',
    }),

  Password: Joi.string()
    .min(8)
    .pattern(/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])/) // At least one uppercase, one special char, and one number
    .required()
    .messages({
      "string.empty": '"Password" cannot be empty',
      "string.min": '"Password" must be at least {#limit} characters long',
      "string.pattern.base":
        '"Password" must contain at least one uppercase letter, one special character, and one number',
    }),

  ConfirmPassword: Joi.any().equal(Joi.ref("Password")).required().messages({
    "any.only": '"Confirm Password" must match "Password"',
    "string.empty": '"Confirm Password" cannot be empty',
    "any.required": '"Confirm Password" is a required field',
  }),

  OutletName: Joi.string()
    .min(3)
    .pattern(/^[a-zA-Z0-9\s]+$/)
    .required()
    .messages({
      "string.base": '"Outlet Name" should be a type of string',
      "string.empty": '"Outlet Name" cannot be empty',
      "string.min": '"Outlet Name" should have a minimum length of {#limit}',
      "string.pattern.base":
        '"Outlet Name" can only contain letters, numbers, and spaces',
      "any.required": '"Outlet Name" is a required field',
    }),
});

export default Outlet_Signup_validation_Schema;
