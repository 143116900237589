import { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";
import toast from "react-hot-toast";

const FooterMenu = () => {
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const location = useLocation();

  const isActive = (path) => {
    if (path === "/dashboard") {
      return location.pathname === path; // Exact match for Home
    }
    return location.pathname.startsWith(path); // Starts with for others
  };
  const checktoken = async () => {
    try {
      const res = await axiosInstance.post("/auth/checktoken");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show error message for 401 status
        toast.error(
          error.response.data.message ||
            "Unauthorized access. Please log in again."
        );

        // Clear all items in local storage
        localStorage.clear();

        // Start checking if local storage is empty, then redirect
        redirectToLoginIfLocalStorageCleared();
      } else {
        console.log("Error:", error.message);
      }
    }
  };
  const redirectToLoginIfLocalStorageCleared = () => {
    // Check if localStorage is empty, and redirect to login if true
    const checkLocalStorageCleared = () => {
      if (localStorage.length === 0) {
        // Redirect to login
        window.location.href = "/login";
        localStorage.clear();
      } else {
        // Keep checking until localStorage is cleared
        setTimeout(checkLocalStorageCleared, 500);
      }
    };
    checkLocalStorageCleared();
  };
  useEffect(() => {
    checktoken();
  }, []);
  return (
    <footer className="bg-white text-[#4b6f55] p-4 fixed bottom-0 left-0 right-0 shadow-xl">
      <div className="flex justify-around">
        {/* Home Link */}
        <Link
          to="/dashboard"
          className={`text-center ${
            isActive("/dashboard") ? "text-blue-500 font-bold" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 9l9-7 9 7v12a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
            />
          </svg>
          <span className="block text-sm">Home</span>
        </Link>

        {/* Orders/Delivery Link */}
        <Link
          to="/dashboard/orders"
          className={`text-center ${
            isActive("/dashboard/orders") ? "text-blue-500 font-bold" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 3h18v2H3V3zM3 10h18v2H3v-2zM3 17h18v2H3v-2z"
            />
          </svg>
          <span className="block text-sm">Orders</span>
        </Link>

        {/* Customers Link */}
        <Link
          to="/dashboard/customer/"
          className={`text-center ${
            isActive("/dashboard/customer") ? "text-blue-500 font-bold" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16 11c1.104 0 2 .896 2 2v5H6v-5c0-1.104.896-2 2-2h6zM12 3v2m4 4h2m-8 0H6m2-4H6m8 0h2M4 5h16"
            />
          </svg>
          <span className="block text-sm">Customers</span>
        </Link>

        {/* Inventory Link */}
        <Link
          to="workers"
          className={`text-center flex flex-col items-center ${
            isActive("/dashboard/workers") ? "text-blue-500 font-bold" : ""
          }`}
        >
          <FaUsers
            className={`${
              isActive("/dashboard/workers") ? "text-blue-500" : ""
            }`}
            size={18}
          />
          <span className="block text-sm">Worker</span>
        </Link>

        {/* More Link */}
        <Link
          to="more"
          className={`text-center ${
            isActive("/dashboard/more") ? "text-blue-500 font-bold" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 12c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4zm0 2c-3.313 0-10 1.671-10 5v3h20v-3c0-3.329-6.687-5-10-5z"
            />
          </svg>
          <span className="block text-sm">More</span>
        </Link>
      </div>
    </footer>
  );
};

export default FooterMenu;
