// components/Overview.js
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { SlArrowRightCircle } from 'react-icons/sl';

const Overview = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
      {/* Card 1: Total Orders */}
      <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
        <div className="flex items-center">
          <div className="bg-blue-500 text-white p-3 rounded-full mr-4">
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3h18v18H3V3z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Total Orders</h3>
            <p className="text-sm text-gray-500">123 orders this month</p>
          </div>
        </div>
        <button className="text-blue-500 hover:text-blue-700">
        <SlArrowRightCircle className='font-bold'/>
        </button>
      </div>

      {/* Card 2: Active Users */}
      <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
        <div className="flex items-center">
          <div className="bg-green-500 text-white p-3 rounded-full mr-4">
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 4h16v16H4V4z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Active Users</h3>
            <p className="text-sm text-gray-500">456 users online</p>
          </div>
        </div>
        <button className="text-green-500 hover:text-green-700">
        <SlArrowRightCircle />
        </button>
      </div>

      {/* Card 3: Available Products */}
      <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
        <div className="flex items-center">
          <div className="bg-yellow-500 text-white p-3 rounded-full mr-4">
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2L2 22h20L12 2z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Available Products</h3>
            <p className="text-sm text-gray-500">789 items in stock</p>
          </div>
        </div>
        <button className="text-yellow-500 hover:text-yellow-700">
        <SlArrowRightCircle />
        </button>
      </div>

      {/* Card 4: Total Revenue */}
      <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
        <div className="flex items-center">
          <div className="bg-red-500 text-white p-3 rounded-full mr-4">
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 6h12v12H6V6z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Total Revenue</h3>
            <p className="text-sm text-gray-500">₹123,456 earned</p>
          </div>
        </div>
        <button className="text-red-500 hover:text-red-700">
        <SlArrowRightCircle />
        </button>
      </div>
    </div>
  );
};

export default Overview;
