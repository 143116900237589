import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-10 p-4 flex justify-between items-center">
      {/* Logo Section */}
      <Link to="/" className="flex items-center text-2xl font-bold text-4b6f55">
        <span>ChaiBook</span>
      </Link>

   

      {/* Profile Image */}
      <div className="flex items-center">
        <img
          src="https://via.placeholder.com/40" // Replace with your profile image URL
          alt="Profile"
          className="rounded-full h-10 w-10 border-2 border-4b6f55"
        />
      </div>
    </header>
  );
};

export default Header;
