import React, { useState } from 'react';
import { FaArrowLeft, FaEdit, FaToggleOn, FaToggleOff, FaTrash, FaCalendarAlt, FaChartLine, FaCalendarCheck } from 'react-icons/fa';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, LineElement, PointElement);

const ViewItemComponent = () => {
  const [isEditMode, setEditMode] = useState(false);

  // Dummy item data
  const itemData = {
    name: 'Masala Chai',
    size: '250 ml',
    price: '₹30',
    image: 'https://img.freepik.com/free-vector/watercolor-international-tea-day-illustration_23-2149359154.jpg?t=st=1729767956~exp=1729771556~hmac=52a777fd69791c4afc1163c1c62963c3c2ee16c70750fa43392175a7fbfcea90&w=740',
  };

  // Dummy statistics data
  const totalOrders = 120;
  const avgMonthlyOrders = 20;
  const yearlyOrders = 240;
  const currentMonthOrders = 25;
  const previousMonthOrders = 20;

  // Dummy monthly data for the line chart and list
  const ordersMonthly = [12, 18, 24, 30, 15, 10, 22, 14, 19, 27, 21, 20];
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const barChartData = {
    labels: monthNames,
    datasets: [
      {
        label: 'Orders',
        data: ordersMonthly,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const lineChartData = {
    labels: monthNames,
    datasets: [
      {
        label: 'Monthly Orders',
        data: ordersMonthly,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
        fill: true,
      },
    ],
  };

  const comparisonChartData = {
    labels: ['Previous Month', 'Current Month'],
    datasets: [
      {
        label: 'Orders Comparison',
        data: [previousMonthOrders, currentMonthOrders],
        backgroundColor: ['#FF9F40', '#36A2EB'],
      },
    ],
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  return (
    <div className="">
      {/* Top Navigation Bar */}
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={() => window.history.back()}
          className="text-blue-500"
        >
          <FaArrowLeft size={20} />
        </button>
        <h2 className="text-lg font-semibold text-center flex-1">
          View Item
        </h2>
      </div>

      {/* Item Card */}
      <div className="flex items-center mb-6 bg-white rounded-lg shadow-lg overflow-hidden">
  {/* Image Section */}
  <div className="flex-shrink-0">
    <img
      src={itemData.image}
      alt={itemData.name}
      className="w-24 h-24 ml-2 object-cover rounded-full"
    />
  </div>
  {/* Item Details Section */}
  <div className="flex-1 p-4">
    <h3 className="text-xl font-semibold">{itemData.name}</h3>
    <p className="text-gray-500">{itemData.size}</p>
    <p className="text-green-600 font-bold">{itemData.price}</p>
  </div>
  {/* Action Menu Section */}
  <div className="flex-shrink-0 flex flex-col items-center justify-center p-2 space-y-2">
    <button onClick={handleEditClick} className="text-blue-500 p-2">
      <FaEdit size={20} />
    </button>
    <button className="text-green-500 p-2">
      <FaToggleOn size={20} />
    </button>

    <button className="text-red-500 p-2">
      <FaTrash size={20} />
    </button>
  </div>
</div>

 {/* Edit Item Form */}
 {isEditMode && (
        <div className="bg-white p-6 rounded-lg shadow-lg mt-6">
          <h3 className="text-lg font-semibold mb-4">Edit Item</h3>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Item Name</label>
            <input
              type="text"
              className="w-full p-2 border rounded-md"
              defaultValue={itemData.name}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Size</label>
            <input
              type="text"
              className="w-full p-2 border rounded-md"
              defaultValue={itemData.size}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Price</label>
            <input
              type="text"
              className="w-full p-2 border rounded-md"
              defaultValue={itemData.price}
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
            >
              Cancel
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
              Save Changes
            </button>
          </div>
        </div>
      )}
      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-blue-100 p-4 rounded-lg shadow-lg hover:bg-blue-200 transition duration-200">
          <div className="flex items-center">
            <FaCalendarAlt className="text-blue-500 mr-2" size={24} />
            <h4 className="text-gray-600">Total Orders</h4>
          </div>
          <p className="text-2xl font-bold mt-2">{totalOrders}</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow-lg hover:bg-green-200 transition duration-200">
          <div className="flex items-center">
            <FaChartLine className="text-green-500 mr-2" size={24} />
            <h4 className="text-gray-600">Average Monthly Orders</h4>
          </div>
          <p className="text-2xl font-bold mt-2">{avgMonthlyOrders}</p>
        </div>
        <div className="bg-red-100 p-4 rounded-lg shadow-lg hover:bg-red-200 transition duration-200">
          <div className="flex items-center">
            <FaCalendarCheck className="text-red-500 mr-2" size={24} />
            <h4 className="text-gray-600">Yearly Orders</h4>
          </div>
          <p className="text-2xl font-bold mt-2">{yearlyOrders}</p>
        </div>
      </div>

      {/* Charts - Responsive Layout */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Line Chart */}
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4">Monthly Orders (Line Chart)</h3>
          <Line data={lineChartData} />
        </div>

        {/* Comparison Chart */}
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4">Orders Comparison (Current vs Previous Month)</h3>
          <Bar data={comparisonChartData} />
        </div>

        {/* Bar Chart */}
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4">Orders by Month (Bar Chart)</h3>
          <Bar data={barChartData} />
        </div>
      </div>

      {/* Monthly Order History List */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4">Monthly Orders Summary</h3>
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <ul className="divide-y divide-gray-200">
            {ordersMonthly.map((orders, index) => (
              <li key={index} className="py-2 flex justify-between">
                <span>{monthNames[index]}</span>
                <span className="font-bold">{orders} orders</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

     
    </div>
  );
};

export default ViewItemComponent;
