import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useAddCustomer = ()=>{
    return useMutation({
        mutationFn:async(data)=>{
            const response = await axiosInstance.post('/outlet/customer/addcustomer',data)

            if(!response.data)
            {
                throw new Error("failed to add customer..")
            }
            return response.data;
        }
    })
}
export default useAddCustomer;