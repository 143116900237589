import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Ui/Spinner';

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page after 5 seconds
    const timer = setTimeout(() => {
      
      if(localStorage.getItem("token"))
      {
        navigate("/dashboard")
      }else{
        navigate("/login")
      }
    }, 7000);

    // // Cleanup the timer when component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col justify-between items-center h-screen bg-gradient-to-br from-blue-600 via-blue-500 to-blue-700 p-4 relative overflow-hidden">
      {/* Blur Effect Background */}
      <div className="absolute inset-0 bg-white opacity-20 blur-5xl"></div>
      
      <div className="flex-grow flex flex-col justify-center items-center z-10">
        <img
          src="images/ani-ico.gif"
          width="150"
          height="150"
          alt="ChaiBook Logo"
          className="animate-fade-in-up mix-blend-darken" 
        />
        <h1 className="text-4xl md:text-5xl -mt-4 font-bold font-poppins text-white mb-4 animate-fade-in-up">
          CHAIBOOK
        </h1>
        <Spinner/>
      </div>
    </div>
  );
};

export default SplashScreen;
