import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useUpdateItem=()=>{
    return useMutation({
        mutationFn:async({id,data})=>{
            const response = await axiosInstance.put(`/outlet/item/updateitem/${id}`,data);
            if(!response.data)
            {
                throw new Error("failed to update item.");
            }
            return response.data;
        }
    })
}
export default useUpdateItem;