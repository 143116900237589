import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useAddBuildings=()=>{
    return useMutation({
        mutationFn:async(data)=>{
            const response  = await axiosInstance.post('/outlet/customer/addbuilding',data);
            if(!response.data)
            {
                throw new Error('error while adding building');
            }
            return response.data;
        }
    })
}
export default useAddBuildings;