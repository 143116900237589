import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";

const useGetCustomers =()=>{
    return useQuery({
        queryKey:['getcustomers'],
        queryFn:async()=>{
            const response = await axiosInstance.get('/outlet/customer/getcustomers');
            if(!response.data)
            {
                throw new Error("failed to fetch cutomers")
            }
            return response.data;
        }
    })
}

export default useGetCustomers;