import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const MoreRoute = () => {
  return (
    <div >
      {/* Header */}
     

      {/* Main Content */}
      
        <Outlet />
      

      {/* Footer */}
      
    </div>
  );
};

export default MoreRoute;
