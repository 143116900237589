import React from 'react';
import { FaCalendarAlt, FaRupeeSign, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const BillingDetails = () => {
    // Sample dummy data for billing information
    const billingData = [
        { id: 101, month: 'September 2024', totalAmount: 1500, status: 'Paid' },
        { id: 102, month: 'October 2024', totalAmount: 1800, status: 'Pending' },
        { id: 103, month: 'August 2024', totalAmount: 1200, status: 'Paid' },
    ];

    return (
        <div>
            <h3 className="font-semibold text-lg mb-4">Billing Information</h3>
            {billingData.map(billing => (
                <div key={billing.id} className="bg-white p-4 rounded-lg shadow-lg mb-4">
                    <div className="flex justify-between items-center mb-2">
                        <div className="text-gray-700 font-medium">
                            <FaCalendarAlt className="inline-block mr-1 text-blue-500" />
                            <span>Billing ID: {billing.id}</span>
                        </div>
                        <div className={`text-sm font-semibold ${billing.status === 'Paid' ? 'text-green-500' : 'text-red-500'}`}>
                            {billing.status === 'Paid' ? (
                                <FaCheckCircle className="inline-block mr-1" />
                            ) : (
                                <FaTimesCircle className="inline-block mr-1" />
                            )}
                            {billing.status}
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="mb-2">
                        <p className="text-gray-500">
                            <FaCalendarAlt className="inline-block mr-1 text-gray-400" /> 
                            Month: <span className="font-medium text-gray-800">{billing.month}</span>
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="font-semibold text-gray-800">Total Amount:</span>
                        <span className="text-blue-600 flex items-center">
                            <FaRupeeSign className="inline-block mr-1" />
                            ₹{billing.totalAmount}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BillingDetails;
