import { FaUserPlus, FaClipboardList, FaPlusCircle, FaEye, FaFileInvoiceDollar, FaChartBar, FaCog } from 'react-icons/fa';

const QuickActions = () => {
    const actions = [
        { icon: <FaClipboardList />, label: 'Orders', action: () => alert('Add Order Clicked') },
        
        { icon: <FaEye />, label: 'Items', action: () => alert('Items') },
        { icon: <FaChartBar />, label: 'Billing', action: () => alert('Billing Clicked') },
        { icon: <FaUserPlus />, label: 'Add Customer', action: () => alert('Add Customer Clicked') },
        { icon: <FaFileInvoiceDollar />, label: 'Generate Report', action: () => alert('Generate Report Clicked') },
        { icon: <FaCog />, label: 'More Options', action: () => alert('More Options Clicked') },
    ];

    return (
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-4 p-6">
            {actions.map((action, index) => (
                <button
                    key={index}
                    onClick={action.action}
                    className="flex flex-col items-center justify-center w-full p-3 bg-white shadow-md rounded-lg hover:bg-gray-100 transition-all duration-200"
                >
                    <div className="text-blue-500 text-xl mb-1">
                        {action.icon}
                    </div>
                    <span className="text-gray-800 font-medium text-xs text-center">{action.label}</span>
                </button>
            ))}
        </div>
    );
};

export default QuickActions;
